import React from 'react'
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import MuiWrapper from '@/hoc/MuiWrapper'
import { darken, fade } from '@material-ui/core/styles/colorManipulator'
import { desktop, laptop, tabletL, mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'

const ExpansionPanelSummary = props => {
  const { theme, classes, children, className, ...other } = props
  return (
    <MuiExpansionPanelSummary
      className={className}
      classes={{
        ...get(classes)
      }}
      {...other}
    >
      { children }
    </MuiExpansionPanelSummary>
  )
}

const getColors = (props) => {
  if (props.type === 'contrast') {
    return {
      text: props.theme.text.contrast,
      background: props.theme.background.contrast,
      border: darken(props.theme.background.default, 0.1)
    }
  } else if (props.scale) {
    return {
      text: props.theme.scale.contrast[props.scale],
      background: props.theme.scale[props.scale],
      border: fade(props.theme.scale.contrast[props.scale], 0.4)
    }
  } else {
    return {
      text: props.theme.text.default,
      background: props.theme.background.default,
      border: fade(props.theme.background.contrast, 0.4)
    }
  }
}

export const make = props => {
  const colors = getColors(props)
  const media = {
    mobile: `@media ${mq.and(mobile)}`,
    large: `@media ${mq.and(desktop, laptop, tabletL)}`
  }
  return {
    ExpansionPanelSummary_root: {
      padding: '20px 32px',
      [media.mobile]: {
        padding: '20px 16px'
      },
      backgroundColor: colors.background,
      [media.large]: {
        position: 'sticky',
        top: 0,
        zIndex: 1
      },
      borderBottom: `2px solid ${colors.border}`
    },
    ExpansionPanelSummary_content: {
      margin: '12px 0',
      [media.mobile]: {
        margin: '0'
      }
    },
    ExpansionPanelSummary_expandIcon: {
      color: colors.text,
      margin: '0 !important'
    },
    ExpansionPanelSummary_expanded: {
    }
  }
}

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('ExpansionPanelSummary_') === 0) {
      filteredClasses[className.replace('ExpansionPanelSummary_', '')] = classes[className]
    }
  })
  return filteredClasses
}

export default MuiWrapper(ThemeConsumer(
  WithStylesProps(make)(ExpansionPanelSummary)
), 'ExpansionPanelSummary')
