import React from 'react'
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import WithStylesProps from '@/hoc/WithStylesProps'
import MuiWrapper from '@/hoc/MuiWrapper'

const ExpansionPanelDetails = props => {
  const { theme, classes, children, className, ...other } = props
  return (
    <MuiExpansionPanelDetails
      className={className}
      classes={{
        ...get(classes)
      }}
      {...other}
    >
      { children }
    </MuiExpansionPanelDetails>
  )
}

export const make = props => {
  return {
    ExpansionPanelDetails_root: {
      padding: '0'
    }
  }
}

export const get = classes => {
  const filteredClasses = {}
  Object.keys(classes).forEach(className => {
    if (className.indexOf('ExpansionPanelDetails_') === 0) {
      filteredClasses[className.replace('ExpansionPanelDetails_', '')] = classes[className]
    }
  })
  return filteredClasses
}

export default MuiWrapper(ThemeConsumer(
  WithStylesProps(make)(ExpansionPanelDetails)
), 'ExpansionPanelDetails')
