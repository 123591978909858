import React, { Fragment } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import PageBase from '@/components/base/Page'
import SEO from '@/components/head/seo'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import PageTitle from '@/components/common/PageTitle'
import imgTitle from '@/images/title-contact.png'
import Forms from '@/components/modules/Contact/Forms'
import MainMessage from '@/components/common/MainMessage'
import Breadcrumb from '@/components/common/Breadcrumb.container'
import RecruitLead from '@/components/modules/Recruit/Lead'

const ContactIndexPage = props => (
  <Root>
    <SEO location={props.location} title="お問い合わせ" description=""/>
    <Head>
      <PageTitle src={imgTitle}>お問い合わせ</PageTitle>
      <MainMessage
        body={(
          <Fragment>お問い合わせ内容に応じ各種窓口をご用意しております。<br />各お問い合わせ先より、ご連絡ください。</Fragment>
        )}
        css={styles.MainMessage}
      />
    </Head>
    <Main>
      <Row css={styles.Forms}><Forms /></Row>
      <Row css={styles.Breadcrumb}><Breadcrumb path={props.location.pathname}/></Row>
      <Row css={styles.Recruit}><RecruitLead /></Row>
    </Main>
  </Root>
)

const Root = styled.div``

const Head = styled.div``

const Main = styled.div``

const Row = styled.div`
  position: relative;
  padding: 64px 0;
  @media ${mq.and(mobile)} {
    padding: 32px 0;
  }
`

const styles = {
  MainMessage: css`
    br {
      display: initial;
    }
    @media ${mq.and(mobile)} {
      br {
        display: none;
      }
    }
  `,
  Forms: css`
    padding: 0 0 64px;
    @media ${mq.and(mobile)} {
      padding: 0 0 32px;
    }
  `,
  Breadcrumb: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 40px 0 0;
    }
  `,
  Recruit: css`
    padding: 0;
    @media ${mq.and(mobile)} {
      padding: 0;
    }
  `
}

export default PageBase(ContactIndexPage)
