import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import ExpansionPanel from '@/components/common/ExpansionPanel'
import ExpansionPanelSummary from '@/components/common/ExpansionPanelSummary'
import ExpansionPanelDetails from '@/components/common/ExpansionPanelDetails'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@/components/common/Typography'
import Form from '@/components/common/Form'

const ContactForms = ({ theme, forms, classes, ...other }) => (
  <StaticQuery
    query={query}
    render={data => {
      const forms = data.forms.edges.map(el => el.node)
      return (
        <Root {...other}>
          {forms.map((form, index) => (
            <ExpansionPanel
              key={index}>
              <ExpansionPanelSummary
                scale={800}
                expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h4" component="h2">{form.title}</Typography>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <Form {...form} scale={800} css={styles.Form} />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </Root>
      )
    }}
  />
)

const Root = styled.div``

const styles = {
  Form: css`
    width: 100%;
  `
}

export const query = graphql`
  query ContactFormsQuery {
    forms: allContactFormListYaml {
      edges {
        node {
          title
          inputFields {
            name
            label
            required
            type
          }
          action
        }
      }
    }
  }
`

export default ThemeConsumer(ContactForms)
